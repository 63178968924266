<template>
  <div class="coupons-page">
    <HeadNav/>
    <header class="df fdr alc jc-sb">
      <div v-for="(item,index) in tab_list" :key="index"
           :class="index+1===current_tab? 'select-item tab-item':'tab-item'" @click="selectTab(index)">
        {{item}}
        <div class="line" v-if="index+1===current_tab"></div>
      </div>
    </header>
    <yd-infinitescroll v-show="list.length" :callback="loadList" ref="infinitescrollDemo">
      <CouponList
          slot="list"
          :list="list"
          :is_start="true"
          @operate="operate">
      </CouponList>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>
    <!-- 空视图 -->
    <div class="empty-box" v-if="list.length===0&&is_request">
      <Empty tip="暂无优惠券～" :icon="emp_img"></Empty>
    </div>
  </div>
</template>

<script>
import CouponList from "@/components/list/CouponList";
import HeadNav from "@/components/common/HeadNav";
// import AlertTip from "@/components/common/AlertTip";
import {toolTip} from "@/mixins";
import {getUserVouchersList} from "@/services/my";
import Empty from '@/components/empty/Empty'

export default {
  name: "CouponsList",
  components: {
    CouponList,
    Empty,
    HeadNav
  },
  mixins: [toolTip],
  data() {
    return {
      page: 1, // 开始查询的下标
      listRows: 10, // 每次查询的数量
      list: [], // 优惠券列表
      emptyList: false, // 是否为空
      tab_list: ['未使用', '已使用', '已过期'],
      current_tab: 1,//1，未使用；2，已使用；3，已过期
      is_request: 0,
      emp_img: require('@/assets/icon_kong_youhuiquan@3x.png'),
    };
  },
  methods: {
    //tab切换
    selectTab(index) {
      this.current_tab = index + 1;
      this.list = [];
      this.page = 1;
      this.is_request = 0;
      this.loadList();
    },
    // 加载数据
    async loadList() {
      try {
        let params = {
          page: this.page,
          listRows: this.listRows,
          type: this.current_tab
        }
        let status = '';
        if (this.current_tab === 1)
          status = 2;
        else if (this.current_tab === 2)
          status = 4;
        else status = 5;
        const res = await getUserVouchersList(params);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.is_request = 1;
          if (data.total > 0) {
            this.list = [...this.list, ...data.data];
            this.list.forEach(a => {
              a.status = status
            });
            if (this.list.length === data.total) {
              // 所有数据加载完毕
              this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.loadedDone");
              return;
            }
            // 单次请求数据完毕
            this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");
            this.page++;
          }
        }
      } catch (e) {

      }
    },
    // 点击按钮
    operate() {
      if (this.current_tab === 1) {
        this.$router.push({
          path: '/home'
        });
      }
    }
  },
  mounted() {
    this.loadList();
  }
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.coupons-page {
  header {
    .wh(100%, 0.88rem);
    background: @color-white;
    padding: 0 0.8rem 0 0.82rem;
    .sc(0.28rem, @color-light-grey);

    .tab-item {
      height: 100%;
      line-height: 0.88rem;
      position: relative;
    }

    .select-item {
      .sc(0.28rem, var(--main-color));
      font-weight: 500;

    }

    .line {
      position: absolute;
      .wh(0.5rem, 0.04rem);
      border-radius: 0.06rem;
      background: var(--main-color);
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .yd-list-donetip {
    .sc(0.28rem, @color-light-grey);
    margin-bottom: 1.88rem;
  }

  /* 列表为空*/

  .empty-box {
    height: ~"calc(100vh - 0.88rem)";
  }

}

</style>