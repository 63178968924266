var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coupons-page" },
    [
      _c("HeadNav"),
      _c(
        "header",
        { staticClass: "df fdr alc jc-sb" },
        _vm._l(_vm.tab_list, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class:
                index + 1 === _vm.current_tab
                  ? "select-item tab-item"
                  : "tab-item",
              on: {
                click: function ($event) {
                  return _vm.selectTab(index)
                },
              },
            },
            [
              _vm._v("\n      " + _vm._s(item) + "\n      "),
              index + 1 === _vm.current_tab
                ? _c("div", { staticClass: "line" })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _c(
        "yd-infinitescroll",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.list.length,
              expression: "list.length",
            },
          ],
          ref: "infinitescrollDemo",
          attrs: { callback: _vm.loadList },
        },
        [
          _c("CouponList", {
            attrs: { slot: "list", list: _vm.list, is_start: true },
            on: { operate: _vm.operate },
            slot: "list",
          }),
          _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
            _vm._v("- 没有更多了 -"),
          ]),
        ],
        1
      ),
      _vm.list.length === 0 && _vm.is_request
        ? _c(
            "div",
            { staticClass: "empty-box" },
            [
              _c("Empty", {
                attrs: { tip: "暂无优惠券～", icon: _vm.emp_img },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }